import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Info, Panel } from '~/eds';
import { StatusType } from '~/eds/src/types';
import { FlagType, useFlag } from '~/flags';
import { slices } from '~/redux';

import { getStatusChip } from '../utils';

export const AttemptConfiguration = () => {
  const enableWebhookExtraFeatures = useFlag(FlagType.EnableWebhooksComponents);
  const dispatch = useDispatch();

  const { activeAttempt, activeAttemptType: action } = useSelector(
    slices.webhooks.selectors.selectActiveAttempt,
  );

  if (!activeAttempt || action !== 'view') return null;

  const { status, text } = getStatusChip(activeAttempt.status ?? undefined);

  return (
    <Panel
      actions={[
        {
          icon: 'x',
          text: 'Close',
          onClick: () => {
            dispatch(slices.webhooks.actions.resetAttemptConfiguration());
          },
        },
      ]}
      children={
        <Info
          sections={[
            {
              title: activeAttempt.endpointUrl,
              infos: [
                {
                  title: 'Event Type',
                  type: 'text' as const,
                  props: { text: activeAttempt.eventType },
                },
                {
                  title: 'Status',
                  type: 'chips' as const,
                  props: {
                    chips: [
                      {
                        status: status as StatusType,
                        text: text,
                      },
                    ],
                  },
                },
                {
                  title: 'Message ID',
                  type: 'text' as const,
                  props: {
                    text: activeAttempt.messageId,
                  },
                },
                {
                  title: 'Date Sent',
                  type: 'date' as const,
                  props: {
                    date: activeAttempt.dateSent,
                    format: 'full',
                  },
                },
                {
                  title: 'Request Body',
                  type: 'textarea' as const,
                  props: {
                    name: 'requestBody',
                    value: JSON.stringify(activeAttempt.requestBody, null, 2),
                    readOnly: true,
                  },
                },
                {
                  title: 'Response',
                  type: 'textarea' as const,
                  props: {
                    name: 'responseExample',
                    value: JSON.stringify(
                      activeAttempt.responseExample,
                      null,
                      2,
                    ),
                    readOnly: true,
                  },
                },
              ],
            },
          ]}
        />
      }
      footer={{
        actions: enableWebhookExtraFeatures
          ? [
              {
                text: 'Archive',
                level: 'tertiary',
                onClick: () => {
                  dispatch(
                    slices.webhooks.actions.setActiveAttemptType('archive'),
                  );
                },
              },
              {
                text: 'Resend',
                onClick: () => {
                  dispatch(
                    slices.webhooks.actions.setActiveAttemptType('resend'),
                  );
                },
              },
            ]
          : [],
      }}
      hidden={{
        isHidden: false,
        onHide: () => {
          dispatch(slices.webhooks.actions.resetAttemptConfiguration());
        },
      }}
      position="right"
      title="Attempt Configuration"
      width="m"
    />
  );
};
