import { parseDate } from '~/eds';
import { Endpoint } from '~/features/webhooks';
import { courier } from '~/services';

import { EndpointGetResponseDto } from '../../types/courier/endpoints';

interface Response {
  data: EndpointGetResponseDto[];
  meta: {
    itemCount: number;
    totalItems: number;
    itemsPerPage: number;
    totalPages: number;
    currentPage: number;
    hasNext: boolean;
    hasPrevious: boolean;
  };
}

interface Request {
  page?: number;
  pageSize?: number;
  sortBy?: {
    id: keyof Endpoint;
    desc: boolean;
  };
}

export const getEndpoints = async ({
  page,
  pageSize,
  sortBy,
}: Request): Promise<{
  results: Endpoint[];
  total: number;
}> => {
  const queryString = buildQueryString({ page, pageSize, sortBy });

  const response: Response = await courier.get(`/endpoints${queryString}`);

  return {
    results: response.data.map((endpoint) => ({
      ...endpoint,
      events: endpoint.events,
      endpointUrl: endpoint.url,
      id: endpoint.id,
      secret: endpoint.secret ?? '',
      createdBy: endpoint.createdBy,
      dateModified: parseDate(endpoint.dateModified),
      dateCreated: parseDate(endpoint.dateCreated),
      modifiedBy: endpoint.modifiedBy,
    })),
    total: response.meta.totalItems,
  };
};

const buildQueryString = ({ page, pageSize, sortBy }: Request): string => {
  if (!page || !pageSize || !sortBy) {
    return '';
  }

  const queryParams = [];
  if (page) {
    queryParams.push(`page=${page}`);
  }

  if (pageSize) {
    queryParams.push(`pageSize=${pageSize}`);
  }

  if (sortBy) {
    queryParams.push(`sortBy=${sortBy.id}&desc=${sortBy.desc}`);
  }

  return queryParams.length ? `?${queryParams.join('&')}` : '';
};
