import React from 'react';
import { CountryRegionData } from 'react-country-region-selector';

import { InputValue, SharedSelectProps } from '../../../types';
import { Select } from '../Select';

type StateProvinceValue = string;
interface Props<M> extends SharedSelectProps<StateProvinceValue, M> {
  /** Country that will be used to look for it's regions */
  country?: string;
}

/**
 * Check if the region or an array of regions is inside the selected country regions
 */
export const isRegionValid = (
  selectedRegion: string | string[] | null,
  regions: string[],
) => {
  if (!selectedRegion) return false;

  if (Array.isArray(selectedRegion)) {
    return selectedRegion.every((region) => regions.includes(region));
  }
  return regions.includes(selectedRegion);
};

export const getRegionsFromString = (regionsName: string) => {
  if (!regionsName || regionsName.indexOf('|') === -1) {
    return ['No State'];
  }
  return regionsName
    .split('|')
    .map((item) => item.substring(0, item.indexOf('~')));
};

export const StateProvinceSelect = <M extends boolean>({
  country,
  disabled,
  value,
  ...rest
}: Props<M>) => {
  /* the CountryRegionData is an array of arrays with [['country_name', 'country_short_name', 'regions'], ...]
  the regions format is 'region_name~region_short_name|region_name~region_short_name' e.g 'Alabama~AL|Alaska~AK'
  */
  const countryData = CountryRegionData.find((item) => item[0] === country);
  const regionsString = countryData ? countryData[2] : '';
  const regions = getRegionsFromString(regionsString);
  const isValid = isRegionValid(value, regions);
  const stateProvinceOptions = regions.map((province) => {
    return {
      label: province,
      value: province,
    };
  });

  const validValue = (isValid ? value : null) as InputValue<
    StateProvinceValue,
    M
  >;

  return (
    <Select
      disabled={!stateProvinceOptions.length || disabled}
      options={stateProvinceOptions}
      value={validValue}
      {...rest}
    />
  );
};
