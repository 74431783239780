import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  ContentContainer,
  exportXlsx,
  Layout,
  StatusMessage,
  Table,
  useToast,
} from '~/eds';
import { Filter } from '~/evifields';
import { FlagType, useFlag } from '~/flags';
import { api, slices } from '~/redux';

import { AttemptsFilters } from '../AttemptsFilters';
import {
  DEFAULT_LIMIT_ATTEMPTS,
  DEFAULT_PAGE_SIZE_ATTEMPTS,
} from '../constants';
import { Attempt, AttemptType } from '../types';
import { getAttemptsDisabledTooltip, getStatusChip } from '../utils';

export const Attempts = () => {
  const dispatch = useDispatch();
  const { toast } = useToast();
  const enableWebhookExtraFeatures = useFlag(FlagType.EnableWebhooksComponents);

  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE_ATTEMPTS);
  const [sortBy, setSortBy] = useState<
    { id: keyof Attempt; desc: boolean } | undefined
  >({
    id: 'dateSent',
    desc: true,
  });

  const filters = useSelector(slices.webhooks.selectors.selectedFilters);
  const { activeEndpoint } = useSelector(
    slices.webhooks.selectors.selectActiveEndpoint,
  );

  useEffect(() => {
    if (activeEndpoint) {
      const updatedFilters = filters.map((filter) => {
        if (filter.id === 'endpointUrl') {
          return {
            ...filter,
            values: [activeEndpoint.id],
            label: activeEndpoint.endpointUrl,
          };
        }
        if (filter.id === 'datetime') {
          return {
            ...filter,
            values: [
              {
                unit: 'days',
                value: 30,
              },
            ],
          };
        }
        return filter;
      });
      dispatch(slices.webhooks.actions.setFilters(updatedFilters));
    }
  }, [activeEndpoint]);

  const {
    data = { results: [], total: 0 },
    isLoading: isLoadingAttempts,
    error: attemptsError,
  } = api.endpoints.getAttempts.useQuery({
    filters,
    page: pageIndex,
    pageSize,
  });

  const [
    getAttemptsExport,
    { isLoading: isLoadingExportAttempts },
  ] = api.endpoints.getAttempts.useLazyQuery();

  const columns = [
    {
      key: 'endpointUrl',
      title: 'Endpoint URL',
      cellType: 'text',
      mapCellProps: (attempt: Attempt) => ({
        text: attempt.endpointUrl,
      }),
    },
    {
      key: 'eventType',
      title: 'Event Type',
      cellType: 'text',
      mapCellProps: (attempt: Attempt) => ({
        text: attempt.eventType,
      }),
    },
    {
      key: 'status',
      title: 'Status',
      cellType: 'chips',
      mapCellProps: (attempt: Attempt) => {
        const { text, status } = getStatusChip(attempt.status);
        return {
          chips: [
            {
              text,
              status,
            },
          ],
        };
      },
    },
    {
      key: 'messageId',
      title: 'Message ID',
      cellType: 'text',
      mapCellProps: (attempt: Attempt) => ({
        text: attempt.messageId,
      }),
    },
    {
      key: 'dateSent',
      title: 'Date Sent',
      cellType: 'datetime',
      mapCellProps: (attempt: Attempt) => ({
        datetime: attempt.dateSent,
        format: 'full',
      }),
      width: 'm',
    },
  ];

  const handlePaginate = useCallback(
    ({ pageIndex }: { pageIndex: number }) => {
      setPageIndex(pageIndex);
    },
    [pageIndex],
  );

  const handleUpdate = useCallback(
    (state: any, action?: any) => {
      if (action?.type === 'toggleSortBy') {
        const updatedSortBy = state.sortBy[0];

        setSortBy(updatedSortBy);
      }
      if (action?.type === 'setPageSize') {
        setPageSize(action.pageSize);
      }
    },
    [sortBy],
  );

  const handleUpdateAttempt = (attempt: Attempt, action: AttemptType) => {
    dispatch(slices.webhooks.actions.setActiveAttempt(attempt));
    dispatch(slices.webhooks.actions.setActiveAttemptType(action));
  };

  const placeholderContent = attemptsError
    ? {
        title: 'An error occurred',
        description: 'Please try again.',
        image: 'error-page-error',
      }
    : data && !data.results.length && !isLoadingAttempts
    ? { title: 'No attempts found', description: 'Try changing your filters.' }
    : undefined;

  const disabledTooltip = getAttemptsDisabledTooltip(isLoadingExportAttempts);
  const rowActions = enableWebhookExtraFeatures
    ? [
        {
          label: 'Resend',
          onClick: (attempt: Attempt) => {
            handleUpdateAttempt(attempt, 'resend');
          },
        },
        {
          label: 'Archive',
          onClick: (attempt: Attempt) => {
            handleUpdateAttempt(attempt, 'archive');
          },
        },
      ]
    : [];

  return (
    <Layout preset="sections">
      <AttemptsFilters
        filters={filters}
        onChange={(updatedFilters: Filter[]) => {
          dispatch(slices.webhooks.actions.setFilters(updatedFilters));
        }}
      />
      {data.total > DEFAULT_LIMIT_ATTEMPTS && (
        <StatusMessage
          message="The maximum results is 50,000 and the current results are over the limit.  Change the filters to get 50,000 or less results."
          status="warning"
        />
      )}
      <ContentContainer placeholderContent={placeholderContent}>
        <Table
          actions={[
            {
              label: 'Export',
              icon: 'download',
              disabled: isLoadingExportAttempts,
              tooltip: disabledTooltip,
              onClick: (tableState: any) => {
                const { columnOrder, name } = tableState;
                getAttemptsExport({
                  filters,
                  sortBy,
                  pageSize: data.total,
                  page: 1,
                }).then((response) => {
                  const prepareResponse = (response.data?.results ?? []).map(
                    (record) => {
                      return columnOrder.reduce(
                        (newRecord: any, key: string) => {
                          if (key in record) {
                            newRecord[key] = record[key as keyof Attempt];
                          }

                          return newRecord;
                        },
                        {},
                      );
                    },
                  );
                  exportXlsx({
                    data: prepareResponse,
                    name,
                  });
                  toast({
                    status: 'success',
                    message: 'Attempts exported successfully.',
                  });
                });
              },
            },
          ]}
          columns={columns}
          data={data.results}
          isLoading={isLoadingAttempts}
          options={{
            enableManageColumns: true,
            enableSelectRows: false,
            enableExportXlsx: false,
            enablePagination: true,
            enablePageSizeSelect: true,
            pageSizes: [50, 100, 250, 500],
          }}
          rowActions={rowActions}
          rowDetails={{
            onClick: (attempt: Attempt) => {
              handleUpdateAttempt(attempt, 'view');
            },
          }}
          onPaginate={handlePaginate}
          onUpdate={handleUpdate}
          reactTableOptions={{
            manualSortBy: true,
          }}
          state={{
            pageIndex,
            pageSize,
            sortBy: [sortBy],
          }}
          totalCount={data.total}
        />
      </ContentContainer>
    </Layout>
  );
};
