import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  DEFAULT_POLL_INTERVAL_MS,
  ErrorPage,
  InfoCard,
  Layout,
  Modal,
  useToast,
} from '~/eds';
import { DataField } from '~/features/fields';
import { actions, api, selectors } from '~/redux';
import { RoutePathType, useRouting } from '~/routing';
import { Nullable } from '~/types';

import { OptimizeResultTag } from '../../../types';
import { BoostProgress } from './BoostProgress';
import { BoostResults } from './BoostResults';

interface Props {
  field: Nullable<DataField>;
  onRefetch: () => void;
}

export const BoostModal = ({ field, onRefetch }: Props) => {
  const { navigate } = useRouting();
  const dispatch = useDispatch();
  const { toast } = useToast();

  const optimize = useSelector(selectors.selectFieldAiOptimize);

  const [pollingInterval, setPollingInterval] = useState<number>(
    DEFAULT_POLL_INTERVAL_MS,
  );

  const {
    data: optimizeResults = null,
    isLoading: isLoadingOptimizeResults,
    error: optimizeResultsError,
  } = api.endpoints.getPromptModelOptimizeResults.useQuery(
    // @ts-expect-error -- this query is skipped if optimize is null
    { modelId: optimize?.modelId, version: optimize?.version },
    {
      skip: !optimize,
      // TODO figure out stop polling when optimizeResults.state === 'done'
      pollingInterval,
    },
  );

  // TODO remove this once above TODO is resolved
  useEffect(() => {
    if (optimizeResults?.state === 'done') {
      setPollingInterval(0);
    }
  }, [optimizeResults]);

  const [
    createPromptModelVersion,
    { isLoading: isCreatingPromptModelVersion },
  ] = api.endpoints.createPromptModelVersion.useMutation();

  const [
    retryOptimizePromptModel,
    { isLoading: isRetryingOptimizePromptModel },
  ] = api.endpoints.optimizePromptModel.useMutation();

  const [selectedOptimizeTag, setSelectedOptimizeTag] = useState<
    Nullable<OptimizeResultTag>
  >(null);

  const cancelOptimize = () => {
    navigate(RoutePathType.AutomationHubFields);
    dispatch(actions.resetFieldAi());
    dispatch(actions.setFieldAiOptimize(null));
  };

  const handleConfirmOptimize = (
    selectedTag: OptimizeResultTag,
    options: { showToast: boolean } = { showToast: true },
  ) => {
    if (selectedTag === 'best_overall_no_user_config_changes') {
      selectedTag = 'best_overall';
    }
    const { showToast } = options;
    const selectedResult = optimizeResults!.results!.find(
      (result) => result.tag === selectedTag,
    );

    if (selectedTag) {
      createPromptModelVersion({
        modelId: optimize!.modelId,
        config: selectedResult!.config,
      })
        .unwrap()
        .then(
          () => {
            // success
            onRefetch();
            dispatch(actions.setFieldAiOptimize(null));
            if (showToast) {
              toast({
                status: 'success',
                message: 'Field Model Updated.',
              });
            }
          },
          () => {
            // error
            if (showToast) {
              toast({
                status: 'danger',
                message: 'Failed to apply optimizations… Please try again.',
              });
            }
          },
        );
    }
  };

  if (!optimize) {
    return null;
  }

  const resolveAction = {
    text: 'Retry Boost',
    variant: 'tertiary' as const,
    isLoading: isRetryingOptimizePromptModel,
    onClick: () => {
      retryOptimizePromptModel({
        modelId: optimize.modelId,
        version: optimize.version,
      });
    },
  };
  const errorContent = (
    <ErrorPage preset="service-unavailable" resolveAction={resolveAction} />
  );

  let content = null;
  let primaryAction;

  if (optimizeResultsError) {
    content = errorContent;
  }

  let results, state, progress;

  if (optimizeResults) {
    results = optimizeResults.results;
    state = optimizeResults.state;
    progress = optimizeResults.progress;

    const progressComplete = progress
      ? Number(progress.completed / progress.total)
      : 0;

    switch (state) {
      case 'initializing':
      case 'running':
        content = (
          <BoostProgress progressComplete={progressComplete} state={state} />
        );
        break;
      case 'done':
        if (results) {
          if (
            results.length === 1 &&
            results[0].tag === 'best_no_user_config_changes' &&
            results[0].testCases.every((testCase) => testCase.isNotChanged)
          ) {
            const { tag } = results[0];
            content = (
              <Layout
                align="center"
                w="25%"
                preset="xl"
                direction="column"
                m="auto"
              >
                <InfoCard
                  icon="status-success"
                  iconStatus="success"
                  action={{
                    onClick: () =>
                      handleConfirmOptimize(tag, { showToast: false }),
                    text: 'Return to Field Model',
                    level: 'primary',
                  }}
                  title="Boost did not find any improvements to your model."
                />
              </Layout>
            );
          } else {
            content = (
              <BoostResults
                results={results}
                selectedOptimizeTag={selectedOptimizeTag}
                onSelectOptimizeTag={setSelectedOptimizeTag}
              />
            );
            primaryAction = {
              isLoading:
                isCreatingPromptModelVersion || isLoadingOptimizeResults,
              text: 'Confirm Selection',
              onClick: () => handleConfirmOptimize(selectedOptimizeTag!),
            };
          }
        }
        break;
      case 'error':
        content = errorContent;
        break;
    }
  }

  return (
    <>
      <Modal
        disableFooter={state === 'initializing' || state === 'running'}
        loadingContent={{
          isLoading: isLoadingOptimizeResults,
          message: 'Loading boosted model…',
        }}
        isFullPage
        isVisible
        onHide={cancelOptimize}
        primaryAction={primaryAction}
        title={`Boosting Field Model: ${field?.label ?? ''}`}
      >
        {content}
      </Modal>
    </>
  );
};
