import React from 'react';

import { Filename, Folder, Icon, Layout, Text } from '~/eds';
import { DataFieldType } from '~/enums';
import { FlexLayout, Link } from '~/ui';
import { getUniqueFieldValues } from '~/utils/document.utils';

export const DEFAULT_TABLE_SORT_COLUMN_ORDER = {
  id: 'name',
  desc: false,
};

export const DEFAULT_TABLE_SORT_COLUMN_PRE_SIGN = {
  id: 'name_Ticket',
  desc: false,
};

export const DEFAULT_TABLE_SETTINGS = {
  columnOrder: [],
  columnWidths: {},
  pageSize: 20,
  sortBy: DEFAULT_TABLE_SORT_COLUMN_ORDER,
};

export const getValueWithBoolean = (bool) => {
  if (bool === true || bool === 'Yes') {
    return 'Yes';
  } else if (bool === false || bool === 'No') {
    return 'No';
  } else {
    return undefined;
  }
};

/**
 * Gets the local storage key for Persisted Tables.
 * @param {User} user - current user
 * @param {string} context - The context of the table usage. (e.g. DOCUMENTS, DASHBOARDS, etc.)
 * @returns {string} the key of local storage.
 */
export function getStorageKey(user, context) {
  return `evi-u${user.id}-table:${context}`;
}

export const renderCellValue = (field, fieldValues = {}) => {
  const value = fieldValues[field.id]?.value;

  switch (field.type) {
    case DataFieldType.ARRAY_MULTIPLE:
      return getUniqueFieldValues(value).join(', ');
    case DataFieldType.ARRAY_SINGLE:
      return value;
    case DataFieldType.BOOLEAN:
      const bool = (value ?? [])[0];
      return getValueWithBoolean(bool);
    case DataFieldType.DATE:
      return (value ?? [])[0];
    case DataFieldType.NUMBER:
      return (value ?? []).join(' • ');

    // below cases will fall through
    case DataFieldType.ATTACHMENT:
    case DataFieldType.STRING:
    case DataFieldType.TEXT_AREA:
    default:
      return value;
  }
};

// dashboards documents filter type is EviFieldType
export const renderDashboardsTableCellValue = (field, fieldValues = {}) => {
  const value = fieldValues[field.id]?.value;

  switch (field.type) {
    case 'enum_set':
    case 'enum':
      return getUniqueFieldValues(value).join(', ');
    case 'boolean':
      const bool = (value ?? [])[0];
      return getValueWithBoolean(bool);
    case 'date':
      return (value ?? [])[0];
    case 'number':
      return (value ?? []).join(' • ');

    // below cases will fall through
    case 'file':
    case 'text':
    case 'text_search':
    default:
      return value;
  }
};

export const FolderValue = ({ handlers = [] }) => {
  if (handlers.length > 1) {
    return (
      <Layout align="center" spacing={2}>
        <Icon icon="folder" role="presentation" />
        <Text>Multiple Locations</Text>
      </Layout>
    );
  } else if (handlers[0]?.path) {
    const handlerPath = handlers[0].path;
    const fullFoldersPath = handlerPath.reduce(
      (acc, val) => [].concat(acc, val.name),
      [],
    );
    return (
      <Layout styles={{ overflow: 'hidden' }}>
        <Folder
          link={{
            pathname: `/documents/${handlerPath[handlerPath.length - 1].id}`,
          }}
          path={fullFoldersPath.join('/')}
        />
      </Layout>
    );
  } else {
    return null;
  }
};

export const HandlerName = ({
  handlers = [],
  openTarget = null,
  onClick,
  onNavigate,
}) => {
  if (handlers?.length) {
    const firstHandler = handlers[0];
    const fileName = `${firstHandler.document_name ?? firstHandler.name}${
      firstHandler.file_type
    }`;

    const handleOnClick = (event) => {
      event.stopPropagation();
      onClick(event);
    };

    return (
      <FlexLayout space={1}>
        {handlers.length > 1 ? (
          <Link onClick={onClick ? handleOnClick : undefined}>
            <Filename name={fileName} includeExtension={false} />
          </Link>
        ) : (
          <Link
            target={openTarget}
            to={`/document/${handlers[0].id}`}
            onClick={() => onNavigate?.(handlers[0].id)}
          >
            <Filename name={fileName} includeExtension={false} />
          </Link>
        )}
      </FlexLayout>
    );
  } else {
    return <Filename name="Deleted" includeExtension={false} />;
  }
};
