import { sub } from 'date-fns';

import { Filter } from '~/evifields';
import { Attempt } from '~/features/webhooks';
import { courier } from '~/services';

import { ListApiArg, PageDto } from '../../types/courier/deliveryAttempt';

type Response = PageDto;

interface Request {
  filters?: Filter[];
  page?: number;
  pageSize?: number;
  sortBy?: {
    id: string;
    desc: boolean;
  };
}

type AttemptsParamPayload = ListApiArg;

export const getAttempts = async ({
  filters,
  page,
  pageSize,
  sortBy,
}: Request): Promise<{
  results: Attempt[];
  total: number;
}> => {
  const params = mapToParams({
    filters,
    page,
    pageSize,
    sortBy,
  });

  const queryString = buildQueryString(params);

  const response: Response = await courier.get(
    `/delivery-attempts${queryString}`,
  );

  return {
    results: response.data.map((attempt) => ({
      ...attempt,
      endpointUrl: attempt.url,
      dateSent: attempt.dateDelivered ? new Date(attempt.dateDelivered) : null,
      status: attempt.deliveryStatus,
      messageId: attempt.id,
    })),
    total: response.meta.totalItems,
  };
};

const mapToParams = ({
  filters,
  page = 1,
  pageSize = 500,
  sortBy,
}: Request) => {
  const queryParam = filters?.reduce((query, filter) => {
    switch (filter.id) {
      case 'endpointUrl':
        query.url = filter.values as string[];
        break;
      case 'status':
        query.deliveryStatus = filter.values as string[];
        break;
      case 'eventType':
        query.eventType = filter.values as string[];
        break;
      case 'datetime':
        const { unit, value } = filter.values[0] as {
          unit: string;
          value: number;
        };
        const from = sub(new Date(), {
          [unit]: value,
        });
        query.dateCreated = from.toString() as string;
        break;
    }

    return query;
  }, {} as AttemptsParamPayload);

  return {
    page: page?.toString(),
    pageSize: pageSize?.toString(),
    endpointId:
      (queryParam?.url?.length ?? 0) > 0 ? queryParam?.url : undefined,
    deliveryStatus:
      (queryParam?.deliveryStatus?.length ?? 0) > 0
        ? queryParam?.deliveryStatus
        : undefined,
    eventType:
      (queryParam?.eventType?.length ?? 0) > 0
        ? queryParam?.eventType
        : undefined,
    dateCreated: queryParam?.dateCreated ? queryParam?.dateCreated : undefined,
    sortBy: sortBy ? `${sortBy.id}&desc=${sortBy.desc}` : undefined,
  };
};

const buildQueryString = (params: AttemptsParamPayload) => {
  const queryParams = [];
  if (params.page) {
    queryParams.push(`page=${params.page}`);
  }
  if (params.pageSize) {
    queryParams.push(`pageSize=${params.pageSize}`);
  }
  if (params.deliveryStatus) {
    console.log(params.deliveryStatus);
    queryParams.push(
      `deliveryStatus=${encodeURIComponent(params.deliveryStatus.join(','))}`,
    );
  }
  if (params.eventType) {
    queryParams.push(
      `eventType=${encodeURIComponent(params.eventType.join(','))}`,
    );
  }
  if (params.dateCreated) {
    queryParams.push(`dateCreated=${params.dateCreated}`);
  }
  if (params.endpointId) {
    queryParams.push(
      `endpointId=${encodeURIComponent(params.endpointId.join(','))}`,
    );
  }
  if (params.sortBy) {
    queryParams.push(`sortBy=${params.sortBy}`);
  }

  return queryParams.length ? `?${queryParams.join('&')}` : '';
};
